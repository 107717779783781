import Actions from 'pages/opportunity/actions';
import PropertiesPage from '../pages/properties';

const propertiesRoutes = {
  path: 'properties',
  element: <PropertiesPage />,
  children: [
    {
      path: ':opportunity_id/brokerage-action/:action',
      element: <Actions />
    }
  ]
};

export default propertiesRoutes;
