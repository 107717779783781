import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { FeaturedFlag } from 'types/admin-config';
import { ErrorValidation } from 'types/error-types';

export const validateFeaturedFlag = (data: FeaturedFlag) => {
  let errors: ErrorValidation = {};

  if (isEmpty(data.name)) {
    errors = {
      ...errors,
      name: [missingField('Flag Name')]
    };
  }

  if (isEmpty(data.target_model)) {
    errors = {
      ...errors,
      target_model: [missingField('Category')]
    };
  }

  if (data.targets.length === 0) {
    errors = {
      ...errors,
      targets: ['Targets can not be blank']
    };
  }

  return {
    errors,
    status: Object.keys(errors).length === 0
  };
};
