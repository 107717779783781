import { Box, Button, Divider, useTheme } from '@mui/material';
import fieldLabel from 'assets/constants/fieldLabel';
import SecondaryButton from 'components/button/button-secondary';
import ConfirmBox, {
  ConfirmModalProps
} from 'components/confirm-box/confirm-box';
import UnitItem from 'components/form/unit-item';
import ModalComponent from 'components/modal';
import ModalHeader from 'components/modal/modal-header';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import { toastError, toastSuccess } from 'event/toast-event';
import { useState } from 'react';
import marketPreferenceBrokerageUserService from 'services/market-preference-brokerage-users-service';
import userService from 'services/user-service';
import initialConfirmModal from 'state/confirm-box/initial-confirm-box';
import { MarketPreferenceBrokerageUserListEntity } from 'types/market-preference-brokerage-user-types';
import {
  getTableBodyCell,
  getTableHeadCell,
  getTableRow,
  Table,
  TableBody,
  TableHead,
  TableHeadRow
} from 'components/table/table-component';
import { tokens } from 'theme';
import DateUtility from 'helpers/date-helper';
import { Link } from 'react-router-dom';
import RouteLink from 'components/link/route-link';

const InactivateUser = ({
  userId,
  onInactivate
}: {
  userId: string;
  onInactivate: () => void;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const TableHeadCell = getTableHeadCell(colors);
  const Row = getTableRow(colors);
  const Cell = getTableBodyCell(colors);

  const [confirmModal, setConfirmModal] =
    useState<ConfirmModalProps>(initialConfirmModal);

  const [existingRoles, setExistingRoles] = useState<
    MarketPreferenceBrokerageUserListEntity[]
  >([]);

  const inactiveUser = async () => {
    const result = await userService.inactivateUser(userId);

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    toastSuccess(fieldLabel.userInactiveSuccess);
    onInactivate();
  };

  const loadBrokerageUserRoles = async () => {
    const result = await marketPreferenceBrokerageUserService.getRoles(userId);

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.data.data.length == 0) {
      inactiveUser();
      return;
    }

    setExistingRoles(result.data.data);
  };

  const RolesTable = ({
    rows,
    onClose
  }: {
    rows: MarketPreferenceBrokerageUserListEntity[];
    onClose: () => void;
  }) => {
    return (
      <PaperBox>
        <UnitItem grid={{ xs: 12, sm: 12 }}>
          <PaperBoxHeader
            value={
              <ModalHeader
                title={fieldLabel.userIsActiveOnMarketPreference}
                onClose={() => {
                  onClose();
                }}
              />
            }
            sx={{ border: 'none' }}
          />
        </UnitItem>

        <PaperBoxContent>
          <Box
            sx={{
              maxHeight: 432,
              overflowY: 'scroll'
            }}
          >
            <Table width={'100px'}>
              <TableHead>
                <TableHeadRow sx={{ backgroundColor: colors.primary[400] }}>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Brokerage User</TableHeadCell>
                  <TableHeadCell>Brokerage Role</TableHeadCell>
                  <TableHeadCell>Status</TableHeadCell>
                  <TableHeadCell>Last Activated At</TableHeadCell>
                  <TableHeadCell>Last Deactivated At</TableHeadCell>
                </TableHeadRow>
              </TableHead>
              <TableBody>
                {rows.map((role) => (
                  <Row
                    sx={{
                      backgroundColor: 'white !important'
                    }}
                    key={role.id}
                  >
                    <Cell sx={{ pt: 1 }}>
                      <RouteLink
                        url={`/market-preferences/${role.market_preference_id}/market-preferences-brokerage-users`}
                        name={role.name}
                        style={{ color: '#2596be' }}
                        target
                      />
                    </Cell>
                    <Cell
                      sx={{ pt: 1 }}
                    >{`${role.brokerage_user.first_name} ${role.brokerage_user.last_name}`}</Cell>
                    <Cell sx={{ pt: 1 }}>
                      {role.brokerage_transaction_role.name}
                    </Cell>
                    <Cell sx={{ pt: 1 }}>{role.status}</Cell>
                    <Cell sx={{ pt: 1 }}>
                      {DateUtility.getDateTimeString(role.last_activated_at)}
                    </Cell>
                    <Cell sx={{ pt: 1 }}>
                      {DateUtility.getDateTimeString(role.last_deactivated_at)}
                    </Cell>
                  </Row>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PaperBoxContent>
      </PaperBox>
    );
  };

  return (
    <>
      <Button
        sx={{ color: 'error' }}
        variant="contained"
        onClick={() => {
          setConfirmModal({
            open: true,
            title: fieldLabel.areYouSure,
            text: `${fieldLabel.areYouSure} to inactive user?`,
            proceed: () => {
              setConfirmModal(initialConfirmModal);
              loadBrokerageUserRoles();
            },
            cancel: () => {
              setConfirmModal(initialConfirmModal);
            }
          });
        }}
        color="error"
      >
        Inactivate
      </Button>

      {existingRoles.length > 0 && (
        <ModalComponent
          Component={RolesTable}
          data={{
            rows: existingRoles,
            onClose: () => setExistingRoles([])
          }}
          onClose={() => setExistingRoles([])}
          loading={false}
          size="lg"
          isConfirmationBox={true}
        />
      )}

      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </>
  );
};

export default InactivateUser;
