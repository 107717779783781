import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/account/default-sort';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useEffect, useState } from 'react';
import useRouteName from 'pages/route-outlet-context';
import { BrokerageUserListEntity } from 'types/user';
import userService from 'services/user-service';
import usersColumn from 'assets/list/users/columns';
import UnitItem from 'components/form/unit-item';
import ProfileCreate from 'features/profile/profile-create';
import AddButton from 'components/form/button-add';
import ModalComponent from 'components/modal';
import fieldLabel from 'assets/constants/fieldLabel';
import { isEmpty } from 'helpers/misc-helper';

const UsersList = () => {
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reload, setReload] = useState(false);

  const [activeUser, setActiveUser] = useState<string | null>();

  const getUsers = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<BrokerageUserListEntity>> => {
    setActiveUser(undefined);

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: {}
    });

    setIsLoading(true);

    const result = await userService.getList(queryString);

    setIsLoading(false);
    setReload(false);
    return result;
  };

  const onEdit = (id: string) => setActiveUser(id);

  useEffect(() => {
    setRouteName('users');
  }, []);

  return (
    <>
      <UnitItem>
        <AddButton
          onClick={() => setActiveUser(null)}
          sx={{ float: 'right' }}
        />
      </UnitItem>
      <DataGrid<BrokerageUserListEntity>
        getData={getUsers}
        tableColumns={usersColumn({ onEdit })}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        isFilterChanged={reload}
      />

      {activeUser !== undefined && (
        <ModalComponent
          title={''}
          Component={ProfileCreate}
          data={{
            routeTag: isEmpty(activeUser) ? 'user-add' : 'user-edit',
            userId: activeUser,
            onCancel: (reloadUsers: boolean) => {
              setActiveUser(undefined);
              if (reloadUsers) setReload(!reload);
            }
          }}
          onClose={() => setActiveUser(undefined)}
          loading={false}
          size="md"
          isServiceCreateBox={true}
        />
      )}
    </>
  );
};

export default UsersList;
