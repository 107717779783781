import { ObjectType } from 'types';

const categoriesOptions: ObjectType = {
  user: 'User',
  account: 'Account',
  market_preference: 'Market Preference',
  contract: 'Contract'
};

export default categoriesOptions;
