import { ChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { ProfileEntity } from 'types/user';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import userStatus from 'assets/constants/user-status';
import userType from 'assets/constants/user-type';
import UnitSwitch from 'components/form/unit-switch';
import { isEmpty } from 'helpers/misc-helper';
import UnitEmpty from 'components/form/unit-empty';
import { getItem } from 'helpers/local-storage-helper';

interface RecordViewType {
  profile: ProfileEntity;
  validation?: ErrorValidation;
  handleChange: (e: ChangeEvent) => void;
  readOnly?: boolean;
  routeTag?: string;
  isUserActive: boolean;
}

const RecordView = ({
  profile,
  validation = {},
  handleChange = emptyFunction,
  readOnly = false,
  routeTag,
  isUserActive
}: RecordViewType) => {
  const user = JSON.parse(getItem('user') || '{}');

  const isProfileEdit = routeTag === 'profile-edit';

  const isSystemAdmin = user.user.is_admin === 1;

  return (
    <FormContainer>
      <UnitText
        label={fieldLabel.userName}
        name="user_name"
        value={profile.user_name ?? ''}
        onChange={handleChange}
        error={validation['user_name'] ?? ''}
        required
        readOnly={readOnly}
        disabled={!isSystemAdmin && isProfileEdit}
      />

      <UnitText
        label={fieldLabel.firstName}
        name="first_name"
        value={profile.first_name ?? ''}
        onChange={handleChange}
        error={validation['first_name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.email}
        name="email"
        value={profile.email ?? ''}
        onChange={handleChange}
        error={validation['email'] ?? ''}
        required
        readOnly={readOnly}
        disabled={!isSystemAdmin && isProfileEdit}
      />

      <UnitText
        label={fieldLabel.lastName}
        name="last_name"
        value={profile.last_name ?? ''}
        onChange={handleChange}
        error={validation['last_name'] ?? ''}
        required
        readOnly={readOnly}
      />

      <UnitSelect
        name="status"
        label={fieldLabel.status}
        records={getObjectEntriesAsArray(userStatus)}
        value={profile.status ?? ''}
        onChange={handleChange}
        required
        readOnly={readOnly || (!isProfileEdit && isUserActive)}
        error={validation['user_status']}
        disabled={(!isSystemAdmin && isProfileEdit) || isEmpty(profile.id)}
      />

      <UnitText
        label={fieldLabel.jobTitle}
        name="title"
        value={profile.title ?? ''}
        onChange={handleChange}
        error={validation['title'] ?? ''}
        readOnly={readOnly}
      />

      <UnitSelect
        name="crm_user_type"
        label={fieldLabel.brokerageUserType}
        records={getObjectEntriesAsArray(userType)}
        value={profile.crm_user_type}
        onChange={handleChange}
        readOnly={readOnly}
        error={validation['crm_user_type'] ?? ''}
        required
        disabled={!isSystemAdmin && isProfileEdit}
      />

      <UnitEmpty />

      <UnitText
        label={fieldLabel.workPhone}
        name="phone_work"
        value={profile.phone_work ?? ''}
        onChange={handleChange}
        error={validation['phone_work'] ?? ''}
        readOnly={readOnly}
      />

      <UnitText
        label={fieldLabel.mobile}
        name="phone_mobile"
        value={profile.phone_mobile ?? ''}
        onChange={handleChange}
        error={validation['phone_mobile'] ?? ''}
        readOnly={readOnly}
      />

      <UnitSwitch
        value={profile.allow_login_with_crm_cred ?? 0}
        onChange={handleChange}
        name="allow_login_with_crm_cred"
        label={fieldLabel.allowLoginCRMCredentials}
        disabled={!isSystemAdmin && isProfileEdit}
      />
    </FormContainer>
  );
};

export default RecordView;
