import { Box } from '@mui/material';
import { ChangeEvent, OnKeyDownEvent } from 'types/common-types';
import { isValidFilter } from 'services/filter-service';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { PropertiesFilterEntity } from 'types/dashboard-type';
import Breadcrumbs from 'features/breadcrumbs';
import ClosingProperties from 'features/properties/closing.tsx';
import ContainerRight from 'components/container/right';
import DASHBOARDS from 'assets/constants/dashboards';
import DashboradTabLabel from 'components/form/unit-dashboard-tab';
import fieldLabel from 'assets/constants/fieldLabel';
import QueryResultFilter from 'features/properties/query-results/filter';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import initilaPropertiesFilter from 'state/properties/initial-filter';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import NewProperties from 'features/properties/new';
import OffersProperties from 'features/properties/offers';
import PreOffersProperties from 'features/properties/pre-offers';
import QueryResultProperties from 'features/properties/query-results';
import React, { useContext, useEffect, useState } from 'react';
import TabArea from 'components/tabs/tab-area';
import userPreferenceService from 'services/user-preference-service';
import eventBus from 'helpers/event-bus-helper';

const tabId = 'properties-tab';

const Properties = () => {
  const { opportunity_id, action } = useParams<{
    opportunity_id: string;
    action: string;
  }>();

  const location = useLocation();

  const [filter, setFilter] = useState<PropertiesFilterEntity>(
    initilaPropertiesFilter
  );
  const [activeTab, setActiveTab] = React.useState(0);
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const { setSideNavMenuItems } = useContext(LayoutProvider);

  const changeFilter = (e: ChangeEvent) => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const saveFilter = async (value: PropertiesFilterEntity) => {
    if (isValidFilter(value)) {
      setActiveTab(4);
      setIsFilterChanged(true);
    }
    setFilter(value);

    await userPreferenceService.save({
      category: DASHBOARDS.PROPERTIES_QUERY_RESULT,
      subcategory: DASHBOARDS.FILTER,
      contents: value
    });
  };

  const saveFilterOnEnterPress = (e: OnKeyDownEvent) => {
    if (e.key === 'Enter') {
      saveFilter(filter);
    }
  };

  const loadFilter = async () => {
    const response =
      await userPreferenceService.getItem<PropertiesFilterEntity>({
        category: DASHBOARDS.PROPERTIES_QUERY_RESULT,
        subcategory: DASHBOARDS.FILTER,
        module: fieldLabel.opportunities
      });

    if (response.isSuccess && response.data) {
      setFilter(response.data.contents);
      setIsFilterChanged(true);
    }
  };

  useEffect(() => {
    loadFilter();
  }, []);

  const getMenus = async () => {
    setSideNavMenuItems([]);
  };

  useEffect(() => {
    if (location.pathname.includes('brokerage-action')) {
      if (opportunity_id !== undefined) {
        eventBus.dispatch('show_action_modal', {});
      }
    }
  }, [location]);

  useEffect(() => {
    getMenus();
  }, []);

  const tabItems = () => {
    return {
      closing: <DashboradTabLabel title={fieldLabel.closing} />,
      offers: <DashboradTabLabel title={fieldLabel.offers} />,
      pre_offers: <DashboradTabLabel title={fieldLabel.preOffers} />,
      new: <DashboradTabLabel title={fieldLabel.new} />,
      query_results: <DashboradTabLabel title={fieldLabel.queryResults} />
    };
  };

  return (
    <>
      <ContainerRight p={1}>
        <Breadcrumbs />

        <QueryResultFilter
          filter={filter}
          changeFilter={changeFilter}
          saveFilterOnEnterPress={saveFilterOnEnterPress}
          saveFilter={saveFilter}
        />

        <Box mt={2}>
          <HorizontalTabs
            value={activeTab}
            handleChange={handleChange}
            tabItems={tabItems()}
            tabId={tabId}
          />

          <TabArea index={0} value={activeTab} border={false} tabId={tabId}>
            <ClosingProperties />
          </TabArea>
          <TabArea index={1} value={activeTab} border={false} tabId={tabId}>
            <OffersProperties />
          </TabArea>
          <TabArea index={2} value={activeTab} border={false} tabId={tabId}>
            <PreOffersProperties />
          </TabArea>
          <TabArea index={3} value={activeTab} border={false} tabId={tabId}>
            <NewProperties />
          </TabArea>
          <TabArea index={4} value={activeTab} border={false} tabId={tabId}>
            <QueryResultProperties
              filter={filter}
              isFilterChanged={isFilterChanged}
              setIsFilterChanged={setIsFilterChanged}
            />
          </TabArea>
          <Outlet />
        </Box>
      </ContainerRight>
    </>
  );
};

export default Properties;
