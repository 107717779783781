import {
  DocumentsListEntity,
  DocumentEntity,
  DocumentPayloadEntity
} from 'types/document-types';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse
} from 'types/api-response-types';
import httpService from './http-service';
import envConfig from 'config/env';
import { DocumentRevisionEntity } from 'types/document-revision-types';
import { GenerateContractEntity } from 'types/pdf-template-types';

const documentService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/documents`,

  getList: async (
    query: string
  ): Promise<GetListResponse<DocumentsListEntity>> => {
    const result = await httpService.list<DocumentsListEntity>(
      `${documentService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<DocumentEntity>> => {
    const result = await httpService.get<DocumentEntity>(
      `${documentService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: FormData
  ): Promise<CreateResponse<DocumentEntity>> => {
    const result = await httpService.post<DocumentEntity, FormData>(
      `${documentService.url}`,
      payload
    );
    return result;
  },

  update: async (
    id: string,
    payload: DocumentPayloadEntity
  ): Promise<UpdateResponse<DocumentPayloadEntity>> => {
    const result = await httpService.put<DocumentEntity, DocumentPayloadEntity>(
      `${documentService.url}/${id}`,
      payload
    );
    return result;
  },

  postDocumentsWithRevision: async (data: FormData) => {
    const response = await httpService.post<DocumentRevisionEntity, FormData>(
      `${documentService.url}/revisions`,
      data
    );
    return response;
  },

  generateContractFromDocGen: async (data: GenerateContractEntity) => {
    const response = await httpService.post<GenerateContractEntity>(
      `${documentService.url}/doc-gen`,
      data
    );
    return response;
  }
};

export default documentService;
